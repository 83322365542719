import { useEffect } from 'react'
import { useAccountOrOverride } from './useAccountOrOverride'
import * as Sentry from '@sentry/nextjs'

export const useAddSentryContext = (): void => {
  const { overridden, address, status, isConnected } = useAccountOrOverride()

  useEffect(() => {
    const context = {
      status,
      overridden,
      address,
    }
    console.debug('updating senty wallet context and user', context)
    Sentry.setContext('wallet', context)
    Sentry.setUser({ email: address, id: address })
  }, [overridden, address, status, isConnected])
}
