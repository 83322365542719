import Link from 'next/link'
import Image from 'next/image'
import { DynamicWidget } from '@dynamic-labs/sdk-react-core'
import { useAccountOrOverride } from '../useAccountOrOverride'
import { useRouter } from 'next/router'

export const Topbar = (): JSX.Element => {
  const { overridden, address, removeOverride } = useAccountOrOverride()
  const { pathname } = useRouter()
  return (
    <>
      {process.env.NEXT_PUBLIC_HASHNOTE_ENV === 'dev' && (
        <div className="bg-insight text-center text-sm">DEV ENVIRONMENT</div>
      )}
      {overridden && (
        <div className="text-sm bg-adept text-black text-center">
          Viewing {address} -{' '}
          <a className="underline cursor-pointer" onClick={removeOverride}>
            Remove
          </a>
        </div>
      )}
      <div className="sticky top-0 z-20 flex items-center border-b border-black w-full h-12 bg-white">
        <div className={`relative w-60 h-8`}>
          <Link href="/">
            <Image className="cursor-pointer" alt="Hashnote Logo" src="/HashnoteDarkWide.svg" fill={true} />
          </Link>
        </div>
        <div className="flex flex-row justify-end flex-grow">
          <a
            className={`flex flex-col text-md items-center justify-center px-5 border-r border-black hover:underline hover:text-blue-500 ${
              pathname === '/' ? 'text-black' : 'text-blue-800'
            }`}
            href="/"
          >
            Auctions
          </a>
          <a
            className={`flex flex-col text-md items-center justify-center px-5 border-r border-black hover:underline hover:text-blue-500 ${
              pathname === '/allowances' ? 'text-black' : 'text-blue-800'
            }`}
            href="/allowances"
          >
            Allowances
          </a>
          <a
            className={`flex flex-row text-md items-center justify-center px-5 border-r border-black hover:underline hover:text-blue-500 ${
              pathname === '/allowances' ? 'text-black' : 'text-blue-800'
            }`}
            title="Download product data"
            target="_blank"
            download={true}
            href={`${process.env.NEXT_PUBLIC_HASHNOTE_SERVERLESS_API_URI}/v1/derivatives/products?format=csv`}
          >
            <span>Products</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 inline"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
              />
            </svg>
          </a>
          <DynamicWidget />
        </div>
      </div>
    </>
  )
}
