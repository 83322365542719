import type { NextPage } from 'next'
import dynamic from 'next/dynamic'
import { Topbar } from '../components/Topbar'
import { useAddSentryContext } from '../useAddSentryContext'

const ComponentWithNoSSR = dynamic(() => import('../components/Main'), { ssr: false })
// issue with Nextjs types
const ComponentWithNoSSRAny = ComponentWithNoSSR as any

const TerminalPage: NextPage = () => {
  useAddSentryContext()
  return (
    <>
      <Topbar />
      <ComponentWithNoSSRAny />
    </>
  )
}

TerminalPage.displayName = 'Terminal'
export default TerminalPage
