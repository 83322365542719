import { Address, GetAccountResult } from '@wagmi/core'
import { useRouter } from 'next/router'
import { useAccount } from 'wagmi'

type ExtendedGetAccountResult = GetAccountResult & {
  overridden?: boolean
  removeOverride?: () => void
}

/**
 * Returns the result of wagmi's useAccount unless the user
 * has specified an override address for impersonating another account
 * in a read-only view.
 */
export const useAccountOrOverride = (): ExtendedGetAccountResult => {
  const result = useAccount()
  const { query } = useRouter()

  if (typeof window !== 'undefined') {
    const overrideAddress = query.view

    if (overrideAddress) {
      const overrideResult: ExtendedGetAccountResult = {
        address: overrideAddress as Address,
        isConnected: true,
        isConnecting: false,
        isDisconnected: false,
        isReconnecting: false,
        connector: {} as any,
        status: 'connected',
        overridden: true,
        removeOverride: () => {
          window.history.pushState({}, document.title, window.location.pathname)
          location.reload()
        },
      }
      return overrideResult
    }
  }

  return result
}
